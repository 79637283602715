import React from 'react';
import { ArrowRight, Check } from 'lucide-react';
import * as Dialog from '@radix-ui/react-dialog';
import BookingModal from './BookingModal';

const benefits = [
  'Analyse personnalisée de vos besoins',
  'Estimation des gains de productivité',
  'Plan d\'automatisation sur mesure',
  'Feuille de route détaillée'
];

export default function CTA() {
  return (
    <div className="relative py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-gradient-to-r from-primary to-secondary rounded-3xl shadow-2xl overflow-hidden">
          <div className="px-6 py-12 sm:px-12 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
            <div className="lg:grid lg:grid-cols-2 lg:gap-12 items-center">
              <div className="lg:self-center">
                <h2 className="text-3xl font-extrabold text-white sm:text-4xl xl:text-5xl">
                  <span className="block mb-2">Découvrez votre</span>
                  <span className="block">potentiel d'automatisation</span>
                </h2>
                <p className="mt-4 text-xl leading-relaxed text-indigo-100">
                  Planifiez une consultation gratuite pour explorer les opportunités d'amélioration de votre cabinet
                </p>
                <ul className="mt-8 space-y-4">
                  {benefits.map((benefit, index) => (
                    <li key={index} className="flex items-center">
                      <div className="flex-shrink-0 h-6 w-6 bg-white/20 rounded-full flex items-center justify-center">
                        <Check className="h-4 w-4 text-white" />
                      </div>
                      <span className="ml-3 text-white text-lg">{benefit}</span>
                    </li>
                  ))}
                </ul>
                <Dialog.Root>
                  <Dialog.Trigger asChild>
                    <button className="mt-10 bg-white text-primary px-8 py-4 rounded-xl font-semibold 
                                   inline-flex items-center transition-colors duration-300
                                   hover:bg-gray-50">
                      Planifier une consultation gratuite
                      <ArrowRight className="ml-2 h-5 w-5" />
                    </button>
                  </Dialog.Trigger>
                  <BookingModal />
                </Dialog.Root>
              </div>
              <div className="mt-10 lg:mt-0 relative w-full h-full flex items-center justify-center">
                <div className="w-full max-w-[90%] lg:max-w-none mx-auto lg:mx-0">
                  <video
                    className="w-full h-full rounded-xl object-cover object-center
                             shadow-2xl transition-transform duration-700 hover:scale-105
                             lg:transform lg:translate-x-6 lg:translate-y-6"
                    autoPlay
                    loop
                    muted
                    playsInline
                    disablePictureInPicture
                    controlsList="nodownload noplaybackrate"
                    style={{ pointerEvents: 'none' }}
                  >
                    <source src="https://res.cloudinary.com/dlx1bgbew/video/upload/v1732411109/video-equipe.mp4" type="video/mp4" />
                    Votre navigateur ne supporte pas la lecture de vidéos.
                  </video>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}