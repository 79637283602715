import React, { useState, useEffect } from 'react';
import { X, MessageSquare } from 'lucide-react';

interface ChatPromptProps {
  onClose: () => void;
  hasInteracted: boolean;
}

export default function ChatPrompt({ onClose, hasInteracted }: ChatPromptProps) {
  const [message, setMessage] = useState<string | null>(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isFading, setIsFading] = useState(false);

  const handleClose = () => {
    setIsFading(true);
    setTimeout(() => {
      setIsVisible(false);
      onClose();
    }, 300); // Match the animation duration
  };

  useEffect(() => {
    if (hasInteracted) return;

    // First message after 1 minute
    const firstTimer = setTimeout(() => {
      setMessage("Bonjour ! Besoin d'aide ? Je suis ici pour répondre à vos questions");
      setIsVisible(true);
      setIsFading(false);

      // Auto-dismiss after 10 seconds
      const dismissTimer = setTimeout(handleClose, 10000);
      return () => clearTimeout(dismissTimer);
    }, 60000);

    // Second message after 2 minutes
    const secondTimer = setTimeout(() => {
      setMessage("N'hésitez pas à me poser vos questions ou à cliquer sur le bouton 'Consultation gratuite' en haut pour planifier un rendez-vous.");
      setIsVisible(true);
      setIsFading(false);

      // Auto-dismiss after 10 seconds
      const dismissTimer = setTimeout(handleClose, 10000);
      return () => clearTimeout(dismissTimer);
    }, 120000);

    return () => {
      clearTimeout(firstTimer);
      clearTimeout(secondTimer);
    };
  }, [hasInteracted, onClose]);

  if (!isVisible || !message) return null;

  return (
    <div className={`fixed bottom-24 right-6 max-w-[300px] z-50 ${isFading ? 'animate-fade-out' : 'animate-fade-in'}`}>
      <div className="relative bg-white rounded-2xl shadow-xl overflow-hidden">
        {/* Gradient Header */}
        <div className="gradient-bg px-4 py-3 flex items-center space-x-2">
          <div className="w-6 h-6 rounded-full bg-white/20 flex items-center justify-center">
            <MessageSquare className="h-3.5 w-3.5 text-white" />
          </div>
          <span className="text-sm font-semibold text-white">Assistant Exelisia</span>
          <button
            onClick={handleClose}
            className="absolute top-2 right-2 p-1.5 hover:bg-white/20 rounded-full transition-colors"
            aria-label="Fermer"
          >
            <X className="h-4 w-4 text-white" />
          </button>
        </div>

        {/* Message Content */}
        <div className="p-4 bg-gradient-to-b from-white to-gray-50">
          <p className="text-sm text-gray-700 leading-relaxed">{message}</p>
        </div>

        {/* Visual Indicator */}
        <div className="absolute -bottom-2 right-6 w-4 h-4 bg-white transform rotate-45 shadow-lg" />
      </div>
    </div>
  );
}