import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Features from './components/Features';
import CTA from './components/CTA';
import Footer from './components/Footer';
import PrivacyPolicy from './components/PrivacyPolicy';
import LegalNotice from './components/LegalNotice';
import TermsOfUse from './components/TermsOfUse';
import CookiePolicy from './components/CookiePolicy';
import CookieBanner from './components/CookieBanner';
import ChatBot from './components/ChatBot';
import * as Dialog from '@radix-ui/react-dialog';
import BookingModal from './components/BookingModal';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function HomePage() {
  const query = useQuery();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (query.get('booking') === 'true') {
      setOpen(true);
    }
  }, [query]);

  const handleOpenChange = (isOpen: boolean) => {
    setOpen(isOpen);
    if (!isOpen) {
      // Remove the booking parameter from URL when modal is closed
      navigate('/', { replace: true });
    }
  };

  return (
    <>
      <Navbar />
      <main className="pt-24">
        <Hero />
        <Features />
        <CTA />
      </main>
      <Footer />
      <Dialog.Root open={open} onOpenChange={handleOpenChange}>
        <BookingModal />
      </Dialog.Root>
      <ChatBot />
    </>
  );
}

export default function App() {
  return (
    <Router>
      <div className="min-h-screen bg-white">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/politique-de-confidentialite" element={<PrivacyPolicy />} />
          <Route path="/mentions-legales" element={<LegalNotice />} />
          <Route path="/conditions-utilisation" element={<TermsOfUse />} />
          <Route path="/politique-cookies" element={<CookiePolicy />} />
        </Routes>
        <CookieBanner />
      </div>
    </Router>
  );
}