import React from 'react';
import { ArrowLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

export default function CookiePolicy() {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gray-50 pt-24 pb-16">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <button
          onClick={() => navigate('/')}
          className="flex items-center text-gray-600 hover:text-primary mb-8 transition-colors"
        >
          <ArrowLeft className="h-5 w-5 mr-2" />
          Retour à l'accueil
        </button>

        <div className="bg-white rounded-2xl shadow-sm p-8 md:p-12">
          <h1 className="text-3xl font-bold mb-8">Politique des Cookies</h1>
          
          <div className="space-y-8">
            <section>
              <h2 className="text-xl font-semibold mb-4">1. Qu'est-ce qu'un cookie ?</h2>
              <p className="text-gray-600 leading-relaxed">
                Un cookie est un petit fichier texte déposé sur votre terminal (ordinateur, tablette, mobile) 
                lors de votre visite sur notre site. Les cookies nous permettent de reconnaître votre navigateur 
                et de vous offrir une meilleure expérience de navigation.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-4">2. Les cookies que nous utilisons</h2>
              <div className="space-y-4">
                <div>
                  <h3 className="font-medium mb-2">Cookies essentiels</h3>
                  <p className="text-gray-600">
                    Ces cookies sont nécessaires au fonctionnement du site et ne peuvent pas être désactivés.
                  </p>
                </div>
                
                <div>
                  <h3 className="font-medium mb-2">Cookies analytiques</h3>
                  <p className="text-gray-600">
                    Nous utilisons ces cookies pour analyser l'utilisation du site et améliorer nos services.
                  </p>
                </div>
              </div>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-4">3. Gestion des cookies</h2>
              <p className="text-gray-600 leading-relaxed">
                Vous pouvez à tout moment modifier vos préférences en matière de cookies via les paramètres 
                de votre navigateur. Notez que le blocage de certains types de cookies peut affecter votre 
                expérience sur le site.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-4">4. Durée de conservation</h2>
              <p className="text-gray-600 leading-relaxed">
                Les cookies ont une durée de vie variable :
              </p>
              <ul className="list-disc list-inside text-gray-600 space-y-2 mt-4">
                <li>Cookies de session : supprimés à la fermeture du navigateur</li>
                <li>Cookies persistants : maximum 13 mois conformément aux recommandations de la CNIL</li>
              </ul>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-4">5. Contact</h2>
              <p className="text-gray-600 leading-relaxed">
                Pour toute question concernant notre politique en matière de cookies, contactez-nous à : 
                contact@exelisia.com
              </p>
            </section>

            <div className="text-sm text-gray-500 mt-12 pt-8 border-t">
              Dernière mise à jour : {new Date().toLocaleDateString('fr-FR')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}