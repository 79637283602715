import { useState, useCallback } from 'react';
import { openai, ASSISTANT_ID } from '../lib/openai';
import { supabase } from '../lib/supabase';
import type { Message, ChatState } from '../types/chat';

export function useChat() {
  const [state, setState] = useState<ChatState>({
    messages: [],
    isLoading: false,
    threadId: null
  });

  const saveMessage = async (message: Message, threadId: string) => {
    try {
      await supabase.from('chat_messages').insert({
        thread_id: threadId,
        role: message.role,
        content: message.content
      });
    } catch (error) {
      console.error('Error saving message:', error);
    }
  };

  const createThread = async () => {
    try {
      const thread = await openai.beta.threads.create();
      setState(prev => ({ ...prev, threadId: thread.id }));
      return thread.id;
    } catch (error) {
      console.error('Error creating thread:', error);
      return null;
    }
  };

  const sendMessage = useCallback(async (content: string) => {
    setState(prev => ({
      ...prev,
      messages: [...prev.messages, { role: 'user', content }],
      isLoading: true
    }));

    try {
      let currentThreadId = state.threadId;
      if (!currentThreadId) {
        currentThreadId = await createThread();
        if (!currentThreadId) throw new Error('Failed to create thread');
      }

      // Save user message to database
      await saveMessage({ role: 'user', content }, currentThreadId);

      await openai.beta.threads.messages.create(currentThreadId, {
        role: 'user',
        content
      });

      const run = await openai.beta.threads.runs.create(currentThreadId, {
        assistant_id: ASSISTANT_ID
      });

      let runStatus = await openai.beta.threads.runs.retrieve(currentThreadId, run.id);
      while (runStatus.status !== 'completed') {
        if (runStatus.status === 'failed') {
          throw new Error('Assistant run failed');
        }
        await new Promise(resolve => setTimeout(resolve, 1000));
        runStatus = await openai.beta.threads.runs.retrieve(currentThreadId, run.id);
      }

      const messages = await openai.beta.threads.messages.list(currentThreadId);
      const lastMessage = messages.data[0];
      
      if (lastMessage.role === 'assistant' && lastMessage.content[0].type === 'text') {
        const assistantMessage = {
          role: 'assistant' as const,
          content: lastMessage.content[0].text.value
        };

        // Save assistant message to database
        await saveMessage(assistantMessage, currentThreadId);

        setState(prev => ({
          ...prev,
          messages: [...prev.messages, assistantMessage]
        }));
      }
    } catch (error) {
      console.error('Error:', error);
      const errorMessage = {
        role: 'assistant' as const,
        content: "Je suis désolé, une erreur s'est produite. Veuillez réessayer."
      };

      // Save error message to database if we have a thread ID
      if (state.threadId) {
        await saveMessage(errorMessage, state.threadId);
      }

      setState(prev => ({
        ...prev,
        messages: [...prev.messages, errorMessage]
      }));
    } finally {
      setState(prev => ({ ...prev, isLoading: false }));
    }
  }, [state.threadId]);

  return {
    messages: state.messages,
    isLoading: state.isLoading,
    sendMessage
  };
}