import React from 'react';
import { ArrowLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

export default function TermsOfUse() {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gray-50 pt-24 pb-16">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <button
          onClick={() => navigate('/')}
          className="flex items-center text-gray-600 hover:text-primary mb-8 transition-colors"
        >
          <ArrowLeft className="h-5 w-5 mr-2" />
          Retour à l'accueil
        </button>

        <div className="bg-white rounded-2xl shadow-sm p-8 md:p-12">
          <h1 className="text-3xl font-bold mb-8">Conditions d'Utilisation</h1>
          
          <div className="space-y-8">
            <section>
              <h2 className="text-xl font-semibold mb-4">1. Acceptation des Conditions</h2>
              <p className="text-gray-600 leading-relaxed">
                En accédant et en utilisant le site exelisia.com, vous acceptez d'être lié par les présentes 
                conditions d'utilisation. Si vous n'acceptez pas ces conditions, veuillez ne pas utiliser le site.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-4">2. Description des Services</h2>
              <p className="text-gray-600 leading-relaxed">
                Exelisia fournit des solutions d'automatisation basées sur l'intelligence artificielle pour 
                les professionnels. Nos services incluent l'analyse, l'implémentation et le support de solutions 
                d'automatisation personnalisées.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-4">3. Utilisation du Site</h2>
              <p className="text-gray-600 leading-relaxed">
                Vous vous engagez à utiliser le site conformément aux lois en vigueur et aux présentes conditions. 
                Il est notamment interdit de :
              </p>
              <ul className="list-disc list-inside text-gray-600 space-y-2 mt-4">
                <li>Utiliser le site à des fins illégales</li>
                <li>Tenter de compromettre la sécurité du site</li>
                <li>Collecter des informations sur les autres utilisateurs</li>
                <li>Utiliser le site d'une manière qui pourrait l'endommager ou le rendre inaccessible</li>
              </ul>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-4">4. Propriété Intellectuelle</h2>
              <p className="text-gray-600 leading-relaxed">
                Tout le contenu présent sur le site (textes, images, logos, etc.) est protégé par les droits 
                de propriété intellectuelle. Aucune reproduction ou utilisation n'est autorisée sans accord 
                préalable écrit.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-4">5. Responsabilité</h2>
              <p className="text-gray-600 leading-relaxed">
                Exelisia s'efforce d'assurer l'exactitude des informations présentes sur le site mais ne peut 
                garantir leur exhaustivité ou leur actualité. Nous déclinons toute responsabilité quant aux 
                dommages directs ou indirects résultant de l'utilisation du site.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-4">6. Modification des Conditions</h2>
              <p className="text-gray-600 leading-relaxed">
                Nous nous réservons le droit de modifier ces conditions d'utilisation à tout moment. Les 
                modifications prennent effet dès leur publication sur le site. Il vous appartient de consulter 
                régulièrement ces conditions.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-4">7. Contact</h2>
              <p className="text-gray-600 leading-relaxed">
                Pour toute question concernant ces conditions d'utilisation, vous pouvez nous contacter à 
                l'adresse suivante : contact@exelisia.com
              </p>
            </section>

            <div className="text-sm text-gray-500 mt-12 pt-8 border-t">
              Dernière mise à jour : {new Date().toLocaleDateString('fr-FR')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}