import React, { useState } from 'react';
import { MessageSquare, X } from 'lucide-react';
import * as Dialog from '@radix-ui/react-dialog';
import { useChat } from '../hooks/useChat';
import MessageList from './chat/MessageList';
import ChatInput from './chat/ChatInput';
import ChatPrompt from './ChatPrompt';

export default function ChatBot() {
  const [isOpen, setIsOpen] = useState(false);
  const [hasInteracted, setHasInteracted] = useState(false);
  const { messages, isLoading, sendMessage } = useChat();

  const handleChatOpen = () => {
    setIsOpen(true);
    setHasInteracted(true);
  };

  return (
    <>
      <ChatPrompt onClose={() => setHasInteracted(true)} hasInteracted={hasInteracted} />
      
      <button
        onClick={handleChatOpen}
        className="fixed bottom-6 right-6 w-14 h-14 rounded-full gradient-bg 
                 flex items-center justify-center shadow-lg hover:scale-110 
                 transition-transform duration-300 z-50"
        aria-label="Ouvrir le chat"
      >
        <MessageSquare className="h-6 w-6 text-white" />
      </button>

      <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
        <Dialog.Portal>
          <Dialog.Overlay className="fixed inset-0 bg-black/40 backdrop-blur-sm z-50" />
          <Dialog.Content className="fixed bottom-[98px] right-6 w-[90vw] max-w-[400px] 
                                   max-h-[600px] bg-white rounded-2xl shadow-xl z-50 
                                   flex flex-col">
            <Dialog.Title className="sr-only">Assistant Exelisia Chat</Dialog.Title>
            
            {/* Header */}
            <div className="flex items-center justify-between p-4 border-b">
              <div className="flex items-center space-x-3">
                <div className="w-8 h-8 rounded-full gradient-bg flex items-center justify-center">
                  <MessageSquare className="h-4 w-4 text-white" />
                </div>
                <h2 className="font-semibold">Assistant Exelisia</h2>
              </div>
              <Dialog.Close className="p-2 hover:bg-gray-100 rounded-full">
                <X className="h-5 w-5" />
              </Dialog.Close>
            </div>

            <MessageList messages={messages} isLoading={isLoading} />
            <ChatInput onSend={sendMessage} isLoading={isLoading} />
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
}