import React from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { X } from 'lucide-react';

export default function BookingModal() {
  return (
    <Dialog.Portal>
      <Dialog.Overlay className="fixed inset-0 bg-black/40 backdrop-blur-sm" />
      <Dialog.Content 
        className="fixed top-[50%] left-[50%] max-h-[85vh] w-[90vw] max-w-[500px] 
                 translate-x-[-50%] translate-y-[-50%] rounded-2xl bg-white shadow-xl 
                 overflow-y-auto"
        style={{
          maxHeight: 'calc(100vh - 8rem)'
        }}
      >
        <Dialog.Title className="text-2xl font-bold mb-6 pr-8 pt-6 px-6">
          Planifier une consultation gratuite
        </Dialog.Title>
        
        <Dialog.Close className="absolute top-4 right-4 p-2 rounded-full hover:bg-gray-100">
          <X className="h-5 w-5" />
        </Dialog.Close>

        <div className="px-6 pb-6">
          <div className="w-full">
            <iframe 
              src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ0Uoz7QDy3ojkFp_YO0e5Lvjr0wGhh_F0WMrtN5wfWvMuO3jHO05XGdF3zOg0e7E3W9SQrOBopl?gv=true" 
              style={{ border: 0, width: '100%', height: '600px' }}
              frameBorder="0"
              title="Calendrier de réservation"
            />
          </div>
        </div>
      </Dialog.Content>
    </Dialog.Portal>
  );
}