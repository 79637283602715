import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import { Link } from 'react-router-dom';

export default function CookieBanner() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookie-consent');
    if (!consent) {
      setIsVisible(true);
    }
  }, []);

  const acceptAll = () => {
    localStorage.setItem('cookie-consent', 'all');
    setIsVisible(false);
  };

  const acceptEssential = () => {
    localStorage.setItem('cookie-consent', 'essential');
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 shadow-lg z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
        <div className="flex flex-col md:flex-row items-start md:items-center justify-between gap-4">
          <div className="flex-1">
            <p className="text-gray-600">
              Nous utilisons des cookies pour améliorer votre expérience sur notre site. 
              En savoir plus dans notre{' '}
              <Link to="/politique-cookies" className="text-primary hover:underline">
                politique des cookies
              </Link>.
            </p>
          </div>
          <div className="flex flex-col sm:flex-row gap-3">
            <button
              onClick={acceptEssential}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 
                       rounded-lg hover:bg-gray-200 transition-colors"
            >
              Cookies essentiels uniquement
            </button>
            <button
              onClick={acceptAll}
              className="px-4 py-2 text-sm font-medium text-white gradient-bg 
                       rounded-lg hover:opacity-90 transition-opacity"
            >
              Accepter tous les cookies
            </button>
          </div>
          <button
            onClick={acceptEssential}
            className="absolute top-2 right-2 p-1 text-gray-400 hover:text-gray-600 
                     transition-colors md:hidden"
            aria-label="Fermer"
          >
            <X className="h-5 w-5" />
          </button>
        </div>
      </div>
    </div>
  );
}