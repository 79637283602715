import React from 'react';
import { ArrowLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

export default function LegalNotice() {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gray-50 pt-24 pb-16">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <button
          onClick={() => navigate('/')}
          className="flex items-center text-gray-600 hover:text-primary mb-8 transition-colors"
        >
          <ArrowLeft className="h-5 w-5 mr-2" />
          Retour à l'accueil
        </button>

        <div className="bg-white rounded-2xl shadow-sm p-8 md:p-12">
          <h1 className="text-3xl font-bold mb-8">Mentions Légales</h1>
          
          <div className="space-y-8">
            <section>
              <h2 className="text-xl font-semibold mb-4">1. Informations Légales</h2>
              <p className="text-gray-600 leading-relaxed">
                Le site exelisia.com est édité par BUCHON Benoit, entrepreneur individuel, sous la marque Exelisia,
                immatriculé au Registre du Commerce et des Sociétés de LYON sous le numéro 805 256 229.
              </p>
              <p className="text-gray-600 leading-relaxed mt-4">
                Siège social : 12 rue de la Part-Dieu 69003 LYON<br />
                Email : contact@exelisia.com
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-4">2. Directeur de la Publication</h2>
              <p className="text-gray-600 leading-relaxed">
                Le directeur de la publication est Benoit BUCHON, en sa qualité d'entrepreneur individuel.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-4">3. Hébergement</h2>
              <p className="text-gray-600 leading-relaxed">
                Le site est hébergé par Netlify, Inc.<br />
                44 Montgomery Street, Suite 300<br />
                San Francisco, California 94104<br />
                United States
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-4">4. Propriété Intellectuelle</h2>
              <p className="text-gray-600 leading-relaxed">
                L'ensemble du contenu de ce site (structure, textes, logos, images, etc.) est la propriété 
                exclusive d'Exelisia. Toute reproduction, représentation, modification, 
                publication ou adaptation totale ou partielle des éléments du site est strictement interdite 
                sans autorisation écrite préalable.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-4">5. Données Personnelles</h2>
              <p className="text-gray-600 leading-relaxed">
                Les informations concernant la collecte et le traitement des données personnelles sont 
                détaillées dans notre Politique de Confidentialité.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-4">6. Cookies</h2>
              <p className="text-gray-600 leading-relaxed">
                Le site utilise des cookies pour améliorer l'expérience utilisateur. En navigant sur le site, 
                l'utilisateur accepte l'utilisation de cookies conformément à notre politique de confidentialité.
              </p>
            </section>

            <div className="text-sm text-gray-500 mt-12 pt-8 border-t">
              Dernière mise à jour : {new Date().toLocaleDateString('fr-FR')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}