import React from 'react';
import { Menu, X, Linkedin, Facebook, Instagram, Mail } from 'lucide-react';
import * as Dialog from '@radix-ui/react-dialog';
import BookingModal from './BookingModal';

export default function Navbar() {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <nav className="fixed w-full bg-white/90 backdrop-blur-sm z-50 border-b border-gray-200">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-24 items-center">
          {/* Left side with social media icons */}
          <div className="w-32 md:w-48 flex items-center space-x-4">
            <div className="hidden md:flex items-center space-x-4">
              <a
                href="https://www.linkedin.com/company/exelisia"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-[#0A66C2] p-2.5 rounded-full hover:scale-110 hover:shadow-lg 
                         transform transition-all duration-300 ease-in-out"
                aria-label="LinkedIn"
              >
                <Linkedin className="h-5 w-5 text-white" />
              </a>
              <a
                href="https://www.facebook.com/exelisia/"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-[#1877F2] p-2.5 rounded-full hover:scale-110 hover:shadow-lg 
                         transform transition-all duration-300 ease-in-out"
                aria-label="Facebook"
              >
                <Facebook className="h-5 w-5 text-white" />
              </a>
              <a
                href="https://www.instagram.com/exelisia"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-gradient-to-br from-[#833AB4] via-[#FD1D1D] to-[#FCAF45] p-2.5 rounded-full 
                         hover:scale-110 hover:shadow-lg transform transition-all duration-300 ease-in-out"
                aria-label="Instagram"
              >
                <Instagram className="h-5 w-5 text-white" />
              </a>
              <a
                href="mailto:contact@exelisia.com"
                className="bg-gradient-to-r from-primary to-secondary p-2.5 rounded-full hover:scale-110 
                         hover:shadow-lg transform transition-all duration-300 ease-in-out"
                aria-label="Email"
              >
                <Mail className="h-5 w-5 text-white" />
              </a>
            </div>
            <button 
              onClick={() => setIsOpen(!isOpen)}
              className="md:hidden p-2 rounded-lg hover:bg-gray-100 transition-colors"
              aria-label="Menu"
            >
              {isOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>

          {/* Centered logo */}
          <div className="flex items-center space-x-3 sm:space-x-5">
            <img 
              src="https://res.cloudinary.com/dlx1bgbew/image/upload/v1732407904/logo-exelisia.png" 
              alt="Exelisia Logo" 
              className="h-16 w-16 sm:h-20 sm:w-20 object-contain"
            />
            <span className="font-tomorrow font-extrabold text-2xl sm:text-4xl gradient-text tracking-wider">
              EXELISIA
            </span>
          </div>
          
          {/* Right-aligned CTA */}
          <div className="w-32 md:w-48 flex justify-end">
            <div className="hidden md:block">
              <Dialog.Root>
                <Dialog.Trigger asChild>
                  <button className="nav-btn">
                    <span>Consultation gratuite</span>
                  </button>
                </Dialog.Trigger>
                <BookingModal />
              </Dialog.Root>
            </div>
          </div>
        </div>
      </div>
      
      {isOpen && (
        <div className="md:hidden absolute w-full bg-white border-b border-gray-200">
          <div className="px-4 pt-2 pb-3">
            <div className="flex justify-center space-x-6 py-4">
              <a
                href="https://www.linkedin.com/company/exelisia"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-[#0A66C2] p-3 rounded-full hover:scale-110 hover:shadow-lg 
                         transform transition-all duration-300 ease-in-out"
                aria-label="LinkedIn"
              >
                <Linkedin className="h-6 w-6 text-white" />
              </a>
              <a
                href="https://www.facebook.com/exelisia/"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-[#1877F2] p-3 rounded-full hover:scale-110 hover:shadow-lg 
                         transform transition-all duration-300 ease-in-out"
                aria-label="Facebook"
              >
                <Facebook className="h-6 w-6 text-white" />
              </a>
              <a
                href="https://www.instagram.com/exelisia"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-gradient-to-br from-[#833AB4] via-[#FD1D1D] to-[#FCAF45] p-3 rounded-full 
                         hover:scale-110 hover:shadow-lg transform transition-all duration-300 ease-in-out"
                aria-label="Instagram"
              >
                <Instagram className="h-6 w-6 text-white" />
              </a>
              <a
                href="mailto:contact@exelisia.com"
                className="bg-gradient-to-r from-primary to-secondary p-3 rounded-full hover:scale-110 
                         hover:shadow-lg transform transition-all duration-300 ease-in-out"
                aria-label="Email"
              >
                <Mail className="h-6 w-6 text-white" />
              </a>
            </div>
            <div className="px-3 py-3">
              <Dialog.Root>
                <Dialog.Trigger asChild>
                  <button className="w-full nav-btn">
                    <span>Consultation gratuite</span>
                  </button>
                </Dialog.Trigger>
                <BookingModal />
              </Dialog.Root>
            </div>
          </div>
        </div>
      )}
    </nav>
  );
}