import React from 'react';
import { ArrowLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

export default function PrivacyPolicy() {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gray-50 pt-24 pb-16">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <button
          onClick={() => navigate('/')}
          className="flex items-center text-gray-600 hover:text-primary mb-8 transition-colors"
        >
          <ArrowLeft className="h-5 w-5 mr-2" />
          Retour à l'accueil
        </button>

        <div className="bg-white rounded-2xl shadow-sm p-8 md:p-12">
          <h1 className="text-3xl font-bold mb-8">Politique de Confidentialité</h1>
          
          <div className="space-y-8">
            <section>
              <h2 className="text-xl font-semibold mb-4">1. Introduction</h2>
              <p className="text-gray-600 leading-relaxed">
                Chez Exelisia, nous accordons une grande importance à la protection de vos données personnelles. 
                Cette politique de confidentialité explique comment nous collectons, utilisons et protégeons vos informations 
                lorsque vous utilisez nos services.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-4">2. Collecte des Données</h2>
              <p className="text-gray-600 leading-relaxed mb-4">
                Nous collectons les informations que vous nous fournissez directement, notamment :
              </p>
              <ul className="list-disc list-inside text-gray-600 space-y-2">
                <li>Nom et prénom</li>
                <li>Adresse email professionnelle</li>
                <li>Numéro de téléphone</li>
                <li>Nom de l'entreprise</li>
              </ul>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-4">3. Utilisation des Données</h2>
              <p className="text-gray-600 leading-relaxed">
                Nous utilisons vos données personnelles pour :
              </p>
              <ul className="list-disc list-inside text-gray-600 space-y-2 mt-4">
                <li>Vous fournir nos services d'automatisation</li>
                <li>Communiquer avec vous concernant nos services</li>
                <li>Améliorer nos services et votre expérience utilisateur</li>
                <li>Respecter nos obligations légales</li>
              </ul>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-4">4. Protection des Données</h2>
              <p className="text-gray-600 leading-relaxed">
                Nous mettons en œuvre des mesures de sécurité techniques et organisationnelles appropriées 
                pour protéger vos données personnelles contre tout accès non autorisé, modification, 
                divulgation ou destruction.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-4">5. Vos Droits</h2>
              <p className="text-gray-600 leading-relaxed mb-4">
                Conformément au RGPD, vous disposez des droits suivants :
              </p>
              <ul className="list-disc list-inside text-gray-600 space-y-2">
                <li>Droit d'accès à vos données personnelles</li>
                <li>Droit de rectification de vos données</li>
                <li>Droit à l'effacement de vos données</li>
                <li>Droit à la limitation du traitement</li>
                <li>Droit à la portabilité de vos données</li>
                <li>Droit d'opposition au traitement</li>
              </ul>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-4">6. Contact</h2>
              <p className="text-gray-600 leading-relaxed">
                Pour toute question concernant notre politique de confidentialité ou pour exercer vos droits, 
                vous pouvez nous contacter à l'adresse suivante : contact@exelisia.com
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-4">7. Modifications</h2>
              <p className="text-gray-600 leading-relaxed">
                Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment. 
                Les modifications entrent en vigueur dès leur publication sur notre site web.
              </p>
            </section>

            <div className="text-sm text-gray-500 mt-12 pt-8 border-t">
              Dernière mise à jour : {new Date().toLocaleDateString('fr-FR')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}