import React from 'react';
import { Clock, DollarSign, Heart, Zap, Settings } from 'lucide-react';

const features = [
  {
    icon: Clock,
    title: 'Gain de temps',
    description: 'Déléguez les tâches répétitives à l\'intelligence artificielle et concentrez-vous sur l\'essentiel de votre activité - vos patients.'
  },
  {
    icon: DollarSign,
    title: 'Réduction des coûts',
    description: 'Automatisez vos processus pour réduire les coûts opérationnels et augmenter votre rentabilité.'
  },
  {
    icon: Heart,
    title: 'Amélioration du service patient',
    description: 'Offrez à vos patients une expérience plus rapide et plus fluide grâce à l\'automatisation.'
  },
  {
    icon: Zap,
    title: 'Optimisation de l\'efficacité',
    description: 'Optimisez vos processus et accélérez la prise de décision grâce à des solutions intelligentes.'
  },
  {
    icon: Settings,
    title: 'Solution sur mesure',
    description: 'Bénéficiez d\'une solution 100% personnalisée qui s\'adapte parfaitement à vos besoins et à votre façon de travailler.'
  }
];

export default function Features() {
  return (
    <div id="features" className="py-24 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="section-title">
            <span className="gradient-text">Améliorez</span> votre productivité
          </h2>
          <p className="section-description">
            Des fonctionnalités puissantes conçues pour vous aider à gagner en efficacité, réduire vos coûts et améliorer la qualité de vos services.
          </p>
        </div>

        <div className="mt-16">
          <div className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3 mb-8">
            {features.slice(0, 3).map((feature, index) => (
              <div key={index} className="feature-card group flex flex-col items-center text-center">
                <div className="h-14 w-14 gradient-bg rounded-xl p-3 mb-6 transform transition-transform duration-500 group-hover:scale-110">
                  <feature.icon className="h-8 w-8 text-white" />
                </div>
                <h3 className="text-xl font-semibold mb-3">{feature.title}</h3>
                <p className="text-gray-600 leading-relaxed">{feature.description}</p>
              </div>
            ))}
          </div>
          
          <div className="grid gap-8 sm:grid-cols-2 lg:grid-cols-2 max-w-4xl mx-auto">
            {features.slice(3).map((feature, index) => (
              <div key={index + 3} className="feature-card group flex flex-col items-center text-center">
                <div className="h-14 w-14 gradient-bg rounded-xl p-3 mb-6 transform transition-transform duration-500 group-hover:scale-110">
                  <feature.icon className="h-8 w-8 text-white" />
                </div>
                <h3 className="text-xl font-semibold mb-3">{feature.title}</h3>
                <p className="text-gray-600 leading-relaxed">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}