import React from 'react';
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <footer className="bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
          <p className="text-gray-500">
            © {new Date().getFullYear()} Exelisia. Tous droits réservés.
          </p>
          <div className="flex flex-wrap justify-center gap-6">
            <Link 
              to="/politique-de-confidentialite" 
              className="text-gray-500 hover:text-primary transition-colors duration-200 text-sm"
            >
              Politique de confidentialité
            </Link>
            <Link 
              to="/mentions-legales" 
              className="text-gray-500 hover:text-primary transition-colors duration-200 text-sm"
            >
              Mentions légales
            </Link>
            <Link 
              to="/conditions-utilisation" 
              className="text-gray-500 hover:text-primary transition-colors duration-200 text-sm"
            >
              Conditions d'utilisation
            </Link>
            <Link 
              to="/politique-cookies" 
              className="text-gray-500 hover:text-primary transition-colors duration-200 text-sm"
            >
              Politique des cookies
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}